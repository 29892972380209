import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Incident, IncidentData } from '../../api/incidentsTypes';
import LinkButton from '../../api/components/LinkButton';
import FormattedTimestamp from '../../common/components/FormattedTimestamp';
import { isIncidentClosed, isIncidentUpdated, isIncidentUnread } from '../../common/utils/incidents';
import { DataFilterType } from '../../api/reducers/config';
import ChatIndicator from './ChatIndicator';
import IncidentChangeIndicator from './IncidentChangeIndicator';

import changeIndicatorStyles from './IncidentChangeIndicator.module.css';
import styles from './IncidentRow.module.css';

type Props = {
  iconName: string;
  incident: Incident;
  replacedByIncident: Incident | null | undefined;
  dataFilterTypes: ReadonlyArray<DataFilterType>;
  onFavoriteButtonPressed: (incident: Incident) => void;
  onRowClicked: (incident: Incident) => void;
  setIncidentRef: (id: string, ref: HTMLElement) => void;
  canSeeIncidentType: boolean;
};

export default class IncidentRow extends React.PureComponent<Props> {
  render() {
    const { incident, iconName, dataFilterTypes, replacedByIncident, canSeeIncidentType } = this.props;
    const data = replacedByIncident ? replacedByIncident.currentData : incident.currentData;

    return (
      <IncidentRowStructure
        incidentData={data}
        isClosed={isIncidentClosed(incident.currentData)}
        isFavorite={incident.isFavorite}
        isReplaced={Boolean(incident.currentData.replacedById)}
        isSameCause={data.cause === incident.lastReadData.cause}
        isUnread={isIncidentUnread(incident)}
        isUpdated={isIncidentUpdated(incident)}
        canSeeIncidentType={canSeeIncidentType}
        preferIncidentType={dataFilterTypes.includes('incidentTypes')}
        showChatColumn={true}
        iconName={iconName}
        lastUpdated={incident.lastUpdated}
        threadId={incident.threadId}
        setRef={this.setRef}
        onRowClick={this.handleRowClicked}
        onFavoriteButtonClick={this.handleFavoriteButtonPressed}
      />
    );
  }

  handleFavoriteButtonPressed: (event: any) => void = event => {
    this.props.onFavoriteButtonPressed(this.props.incident);
    event.stopPropagation();
  };

  handleRowClicked: () => void = () => {
    this.props.onRowClicked(this.props.incident);
  };

  setRef = (ref: HTMLElement | null | undefined) => {
    const { setIncidentRef, incident } = this.props;
    if (ref) {
      setIncidentRef(incident.incidentId, ref);
    }
  };
}

type IncidentRowStructureProps = {
  incidentData: IncidentData;
  isClosed?: boolean;
  isFavorite?: boolean;
  isReplaced?: boolean;
  isSameCause?: boolean;
  isUnread?: boolean;
  isUpdated?: boolean;
  canSeeIncidentType?: boolean;
  longDates?: boolean;
  preferIncidentType?: boolean;
  showChatColumn?: boolean;
  iconName: string;
  lastUpdated: string;
  threadId: string;
  setRef: (ref: HTMLElement | null) => void;
  onRowClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  onFavoriteButtonClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const IncidentRowStructure = React.memo(
  ({
    incidentData,
    isClosed = false,
    isFavorite = false,
    isReplaced = false,
    isSameCause = true,
    isUnread = false,
    isUpdated = false,
    canSeeIncidentType = true,
    preferIncidentType = false,
    showChatColumn = false,
    longDates = false,
    iconName,
    lastUpdated,
    threadId,
    setRef,
    onRowClick,
    onFavoriteButtonClick,
  }: IncidentRowStructureProps) => {
    const rowColor = isClosed ? 'greyText' : 'blackText';
    const readStyle = isUnread ? styles.unread : '';
    const iconBackgroundColor = isClosed ? 'greyIcon' : 'tintIcon';
    const highlight = isSameCause ? undefined : 'highlight';
    const iconColor = isClosed ? 'greyText' : 'tint';
    const starColor = isFavorite ? iconColor : 'greyText';
    const updateIconColor = isUnread ? (isClosed ? 'greyIcon' : 'tintIcon') : '';

    const { cause } = incidentData;

    return (
      <tr
        ref={setRef}
        className={`${styles.incidentRow} pointerCursor noSelect ${rowColor} ${readStyle}`}
        onClick={onRowClick}>
        <td className={`${iconBackgroundColor} ${styles.causeIconBodyCol}`}>
          <span className={`icon-${iconName} whiteText ${styles.causeIcon}`} />
        </td>
        <td className={styles.messageCol}>
          <div>
            <span className={highlight}>
              {isReplaced ? (
                <FormattedMessage id="IncidentRow.replaced" defaultMessage="Replaced: {cause}" values={{ cause }} />
              ) : (
                cause
              )}
            </span>
          </div>
        </td>
        <td className={styles.narrowCol}>
          <div>{incidentData.lines.join(', ')}</div>
        </td>
        <td className={styles.narrowCol}>
          <div>{incidentData.locationFrom}</div>
        </td>
        <td className={styles.narrowCol}>
          <div>{incidentData.locationTo}</div>
        </td>
        {canSeeIncidentType && (
          <td className={styles.narrowCol}>
            <div>{preferIncidentType ? incidentData.incidentType : incidentData.regions.join(', ')}</div>
          </td>
        )}
        <td>
          <div>{incidentData.information}</div>
        </td>
        <td className={longDates ? styles.longDateCol : styles.dateCol}>
          <div>
            <FormattedTimestamp value={lastUpdated} fullDate={longDates} />
          </div>
        </td>
        {showChatColumn ? (
          <td className={styles.chatIconBodyCol}>
            <ChatIndicator chatThreadId={threadId} closed={isClosed} />
          </td>
        ) : null}
        {onFavoriteButtonClick ? (
          <td className={styles.favoriteIconBodyCol}>
            <LinkButton onClick={onFavoriteButtonClick} className={styles.favoriteButton}>
              <span className={`${starColor} ${styles.icon} ${isFavorite ? 'icon-star' : 'icon-star_outline'}`} />
            </LinkButton>
          </td>
        ) : null}
        <td className={`${updateIconColor} ${changeIndicatorStyles.changeIndicator}`}>
          <IncidentChangeIndicator isUpdated={isUpdated} />
        </td>
      </tr>
    );
  }
);
