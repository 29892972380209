import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Incident, IncidentData } from '../../api/incidentsTypes';
import LinkButton from '../..//api/components/LinkButton';
import { IncidentsViewMode } from '../../common/selectors/incidentsByViewMode';
import { isIncidentClosed, isIncidentUpdated, isIncidentUnread } from '../../common/utils/incidents';
import FormattedTimestamp from '../../common/components/FormattedTimestamp';
import tableColConstants from '../utils/tableColConstants';
import ChatIndicator from './ChatIndicator';
import IncidentChangeIndicator from './IncidentChangeIndicator';

import colorStyles from '../css/colors.module.css';
import changeIndicatorStyles from './IncidentChangeIndicator.module.css';
import styles from './IncidentHeader.module.css';

type OwnProps = {
  setIncidentRef: (id: string, ref: HTMLElement) => void;
  replacedByIncident?: Incident | null | undefined;
  canSeeIncidentType: boolean;
};

type Props = OwnProps & {
  iconName: string;
  incident: Incident;
  viewMode: IncidentsViewMode;
  onFavoriteButtonPressed: (incident: Incident) => void;
  onRowClicked: (incident: Incident) => void;
  onReadButtonClicked: (incident: Incident) => void;
};

export default class IncidentHeader extends React.Component<Props> {
  render() {
    const { incident, iconName, replacedByIncident, canSeeIncidentType } = this.props;

    const data = replacedByIncident ? replacedByIncident.currentData : incident.currentData;

    const lastReadData = incident.lastReadData;

    const isClosed = isIncidentClosed(incident.currentData);

    const renderReadButton = () => (
      <button
        className={isClosed ? styles.outlineGreySmall : styles.outlineRedSmall}
        onClick={this.handleReadButtonClicked}>
        <FormattedMessage id="IncidentHeader.markAsRead" defaultMessage="Mark as read" />
      </button>
    );

    return (
      <IncidentHeaderStructure
        incidentData={data}
        lastUpdated={incident.lastUpdated}
        threadId={incident.threadId}
        iconName={iconName}
        canSeeIncidentType={canSeeIncidentType}
        isClosed={isClosed}
        isFavorite={incident.isFavorite}
        isUnread={isIncidentUnread(incident)}
        isUpdated={isIncidentUpdated(incident)}
        isReplaced={Boolean(incident.currentData.replacedById)}
        isSameCause={data.cause === lastReadData.cause}
        showChatColumn={true}
        renderReadButton={renderReadButton}
        favorites={this.props.viewMode === ('favorites' as IncidentsViewMode)}
        onRowClick={this.handleRowClicked}
        onFavoriteButtonClick={this.handleFavoriteButtonPressed}
        setRef={this.setRef}
      />
    );
  }

  handleFavoriteButtonPressed = (event: any) => {
    this.props.onFavoriteButtonPressed(this.props.incident);
    event.stopPropagation();
  };

  handleRowClicked = () => {
    this.props.onRowClicked(this.props.incident);
  };

  handleReadButtonClicked = () => {
    this.props.onReadButtonClicked(this.props.incident);
  };

  setRef = (ref: HTMLElement | null | undefined) => {
    const { setIncidentRef, incident } = this.props;
    if (ref) {
      setIncidentRef(incident.incidentId, ref);
    }
  };
}

type IncidentHeaderStructureProps = {
  incidentData: IncidentData;
  isClosed?: boolean;
  isFavorite?: boolean;
  isReplaced?: boolean;
  isSameCause?: boolean;
  isUnread?: boolean;
  isUpdated?: boolean;
  canSeeIncidentType?: boolean;
  preferIncidentType?: boolean;
  showChatColumn?: boolean;
  favorites?: boolean;
  longDates?: boolean;
  iconName: string;
  lastUpdated: string;
  threadId: string;
  setRef: (ref: HTMLElement | null) => void;
  onRowClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  onFavoriteButtonClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  renderPagination?: () => React.ReactNode;
  renderReadButton?: () => React.ReactNode;
};

export const IncidentHeaderStructure = React.memo(
  ({
    incidentData,
    isClosed = false,
    isFavorite = false,
    isReplaced = false,
    isSameCause = true,
    isUnread = false,
    isUpdated = false,
    canSeeIncidentType = true,
    showChatColumn = false,
    favorites = false,
    longDates = false,
    iconName,
    lastUpdated,
    threadId,
    setRef,
    onRowClick,
    onFavoriteButtonClick,
    renderReadButton,
    renderPagination,
  }: IncidentHeaderStructureProps) => {
    const rowColor = isClosed ? 'greyText' : 'blackText';
    const iconBackgroundColor = isClosed ? 'greyIcon' : 'tintIcon';
    const highlight = isSameCause ? undefined : 'highlight';
    const iconColor = isClosed ? 'greyText' : 'tint';
    const starColor = isFavorite ? iconColor : 'greyText';
    const updateIconColor = isUnread ? (isClosed ? 'greyIcon' : 'tintIcon') : '';
    const cursor = favorites ? 'noCursor' : 'pointerCursor';

    const { cause } = incidentData;

    return (
      <tr
        className={`${cursor} noSelect ${colorStyles.toolbarBackground} ${rowColor} ${styles.incidentHeaderRow}`}
        onClick={onRowClick}
        style={{
          fontWeight: isUnread ? 'bold' : undefined,
          borderBottom: '1px solid #d6d6d6',
          borderTop: '1px solid #d6d6d6',
        }}
        ref={setRef}>
        <td
          className={iconBackgroundColor}
          style={{
            textAlign: 'center',
            paddingTop: '8px',
            width: '40px',
          }}>
          <span className={`icon-${iconName} whiteText`} style={{ fontSize: '22px' }} />
        </td>
        <td colSpan={canSeeIncidentType ? 5 : 4}>
          <div className={styles.causeWithPagination}>
            <div className={highlight}>
              {isReplaced ? (
                <FormattedMessage id="IncidentHeader.replaced" defaultMessage="Replaced: {cause}" values={{ cause }} />
              ) : (
                cause
              )}
            </div>
            {renderPagination ? renderPagination() : null}
          </div>
        </td>
        {renderReadButton && isUnread ? (
          <td className={`alignRight ${styles.noPadding}`}>{renderReadButton()}</td>
        ) : (
          <td className={cursor} />
        )}
        <td style={{ width: tableColConstants.date }}>
          <div>
            <FormattedTimestamp value={lastUpdated} fullDate={longDates} />
          </div>
        </td>
        {showChatColumn ? (
          <td style={{ paddingTop: '7px', width: '34px' }}>
            <ChatIndicator chatThreadId={threadId} closed={isClosed} />
          </td>
        ) : null}
        {onFavoriteButtonClick ? (
          <td style={{ paddingTop: '7px', width: '34px' }}>
            <LinkButton onClick={onFavoriteButtonClick} className={styles.noTextDecoration}>
              <span
                className={`${starColor} pointerCursor ${isFavorite ? 'icon-star' : 'icon-star_outline'}`}
                style={{ fontSize: '24px' }}
              />
            </LinkButton>
          </td>
        ) : null}
        <td className={`${updateIconColor} ${changeIndicatorStyles.changeIndicator}`}>
          <IncidentChangeIndicator isUpdated={isUpdated} />
        </td>
      </tr>
    );
  }
);
