import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { DataFilterType } from '../../api/reducers/config';
import colorStyles from '../css/colors.module.css';

import changeIndicatorStyles from './IncidentChangeIndicator.module.css';
import styles from './IncidentRow.module.css';

type Props = {
  dataFilterTypes: ReadonlyArray<DataFilterType>;
  canSeeIncidentType: boolean;
  showChatColumn?: boolean;
  showFavoriteColumn?: boolean;
  longDates?: boolean;
};

export function Header({
  dataFilterTypes,
  canSeeIncidentType,
  showChatColumn = false,
  showFavoriteColumn = false,
  longDates = false,
}: Props) {
  return (
    <thead className={colorStyles.toolbarBackground}>
      <tr>
        <th className={styles.causeIconCol} />
        <th className={styles.messageCol}>
          <FormattedMessage id="IncidentsList.incidentType" defaultMessage="Message Type" />
        </th>
        <th className={styles.narrowCol}>
          <FormattedMessage id="IncidentsList.place" defaultMessage="Line" />
        </th>
        <th className={styles.narrowCol}>
          <FormattedMessage id="IncidentsList.from" defaultMessage="from" />
        </th>
        <th className={styles.narrowCol}>
          <FormattedMessage id="IncidentsList.to" defaultMessage="to" />
        </th>
        {canSeeIncidentType && (
          <th className={styles.narrowCol}>
            {dataFilterTypes.includes('incidentTypes') ? (
              <FormattedMessage id="IncidentsList.filterIncidentType" defaultMessage="Category" />
            ) : (
              <FormattedMessage id="IncidentsList.region" defaultMessage="Region" />
            )}
          </th>
        )}
        <th>
          <FormattedMessage id="IncidentsList.information" defaultMessage="Information" />
        </th>
        <th className={longDates ? styles.longDateCol : styles.dateCol}>
          <FormattedMessage id="IncidentsList.changed" defaultMessage="Changed" />
        </th>
        {showChatColumn ? (
          <th className={styles.chatIconHeaderCol}>
            <span className={`icon-chat ${styles.icon}`} />
          </th>
        ) : null}
        {showFavoriteColumn ? (
          <th className={styles.favoriteIconHeaderCol}>
            <span className={`icon-star_outline ${styles.icon}`} />
          </th>
        ) : null}
        <th className={changeIndicatorStyles.changeIndicatorCol} />
        <th className={styles.scrollbarPlaceHolderCol} />
      </tr>
    </thead>
  );
}

type InvisibleRowProps = {
  canSeeIncidentType: boolean;
  showChatColumn?: boolean;
  showFavoriteColumn?: boolean;
  longDates?: boolean;
};

export const InvisibleRow = ({
  canSeeIncidentType,
  showChatColumn = false,
  showFavoriteColumn = false,
  longDates = false,
}: InvisibleRowProps) => {
  return (
    <tr className={styles.invisibleRow}>
      <td className={styles.causeIconCol} />
      <td className={styles.messageCol} />
      <td className={styles.narrowCol} />
      <td className={styles.narrowCol} />
      <td className={styles.narrowCol} />
      {canSeeIncidentType && <td className={styles.narrowCol} />}
      <td />
      <td className={longDates ? styles.longDateCol : styles.dateCol} />
      {showChatColumn ? <td className={styles.iconCol} /> : null}
      {showFavoriteColumn ? <td className={styles.iconCol} /> : null}
      <td className={changeIndicatorStyles.changeIndicatorCol} />
    </tr>
  );
};
